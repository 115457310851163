<template>
  <div
    class="badge"
    :class="{
      [`badge--${color}`]: color,
      [`badge--${size}`]: size,
      [`badge--border-${border}`]: border,
      [`badge--corner-${bevelCorner}`]: bevelCorner,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

type BadgeColor = 'brown' | 'grey-dark' | 'grey-light' | 'green' | 'green-light' | 'blue' | 'orange' | undefined
type BadgeSize = 'lg' | 'sm' | 'xs' | undefined
type BadgeBevelCorner = 'left-top' | 'right-bottom' | undefined
type BadgeBorder = 'xs' | undefined

export default defineComponent({
  name: 'ABadge',
  props: {
    color: {
      type: String as PropType<BadgeColor>,
      default: undefined
    },
    size: {
      type: String as PropType<BadgeSize>,
      default: undefined
    },
    border: {
      type: String as PropType<BadgeBorder>,
      default: undefined
    },
    bevelCorner: {
      type: String as PropType<BadgeBevelCorner>,
      default: undefined
    }
  }
})
</script>

<style lang="postcss">
.badge {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: fit-content;
  padding: var(--badge-spacer-vertical, var(--spacer-5xs)) var(--spacer-3xs);
  border-radius: var(--badge-border-radius, var(--border-radius-2xs));
  background-color: var(--badge-background-color, var(--color-danger-dark));
  color: var(--badge-color, var(--color-white));

  &--brown {
    --badge-background-color: #cab89c;
  }

  &--grey-dark {
    --badge-background-color: var(--color-neutral-500);
  }

  &--grey-light {
    --badge-background-color: var(--color-neutral-300);
    --badge-color: var(--color-text-light);
  }

  &--green {
    --badge-background-color: var(--color-success-dark);
  }

  &--green-light {
    --badge-color: var(--color-success-dark);
    --badge-background-color: var(--color-green-50);
  }

  &--blue {
    --badge-background-color: var(--color-info-middle);
  }

  &--orange {
    --badge-background-color: var(--color-warning-dark);
  }

  &--lg {
    --badge-corner-position: var(--spacer-3xs);

    @mixin text-3xl;
  }

  &--sm {
    --badge-spacer-vertical: 0;

    @mixin text-sm-bold;
  }

  &--xs {
    --badge-spacer-vertical: 0;

    &.badge {
      @mixin text-xs-bold;
    }
  }

  &--border-xs {
    --badge-border-radius: var(--border-radius-xs);
  }

  &--corner-right-bottom:not(&--border-xs)::after,
  &--corner-left-top:not(&--border-xs)::after {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    display: block;
    width: var(--spacer-xs);
    height: 100%;
    background-color: var(--badge-background-color, var(--color-danger-dark));
    transform: skew(-13deg);
  }

  &--corner-right-bottom:not(&--border-xs) {
    margin-right: var(--spacer-3xs);
    padding-right: var(--spacer-4xs);

    &::after {
      right: calc(var(--badge-corner-position, var(--spacer-2xs)) * -0.5);
      padding-right: var(--spacer-4xs);
      border-radius: 0 var(--border-radius-2xs) var(--border-radius-2xs) 0;
    }
  }

  &--corner-left-top:not(&--border-xs) {
    margin-left: var(--spacer-3xs);
    padding-left: var(--spacer-4xs);

    &::after {
      left: calc(var(--badge-corner-position, var(--spacer-2xs)) * -0.5);
      padding-left: var(--spacer-4xs);
      border-radius: var(--border-radius-2xs) 0 0 var(--border-radius-2xs);
    }
  }

  @mixin text-sm-bold;
}
</style>
